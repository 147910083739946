import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBrokers } from "services/hooks/rentals";
import { setBroker } from "state";
import FormDialog from "components/modal";
import BrokersTable from "components/Tables/BrokersTable";
import BrokerForm from "components/Forms/BrokersForm";

export default function Brokers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [open, setOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);
  const itemsPerPage = 10;

  const [newBroker, setNewBroker] = React.useState({
    broker_name: "",
    broker_location: "",
    broker_contact: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedBroker, setSelectedBroker] = React.useState(null);
  const { brokers, isLoadingBrokers, error, refetch } = useBrokers();
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleMenuClick = (event, broker) => {
    console.log(broker)
    dispatch(setBroker(broker))
    setAnchorEl(event.currentTarget);
  };

  const handleMenuAction = (action, broker) => {
    if (action === "Payments") {
      navigate(`/broker-payments`); 
    }
    if (action === "View") {
      navigate(`/broker-details`);
    }
    handleMenuClose(action);
  };

  const handleMenuClose = (action) => {
    setAnchorEl(null);
    if (action === "Edit") {
      setEditDialogOpen(true);
    } else if (action === "Send Message") {
      setMessageDialogOpen(true);
    } else if (action === "Delete") {
      setDeleteDialogOpen(true);
    } 
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setDeleteDialogOpen(false);
    setOpen(false);
    setNewBroker({
      broker_name: "",
      broker_location: "",
      broker_contact: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewBroker((prevBroker) => ({ ...prevBroker, [name]: value }));
  };

  const handleSubmitBroker = async () => {
    setIsSubmittingData(true);
    try {
      const response = await axios.post(`${baseURL}/landlords/admin/brokers`, newBroker);
      setOpenSnack({ ...openSnack, open: true });
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmittingData(false);
      setOpen(false);
      setNewBroker({
        broker_name: "",
        broker_location: "",
        broker_contact: "",
      });
    }
  };

  const handleClose = () => {
    handleDialogClose();
  };

  const handleSubmit = () => {
    handleSubmitBroker();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box m="20px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" fontSize="16px">
              Brokers
            </Typography>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add Broker
            </Button>
          </Box>

          <FormDialog
            open={open}
            title={"Add Broker"}
            text={"Assign a new Broker"}
            content={
              <BrokerForm
                open={open}
                handleDialogClose={handleDialogClose}
                handleSubmitBroker={handleSubmitBroker}
                handleChange={handleChange}
                newBroker={newBroker}
              />
            }
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isSubmittingData={isSubmittingData}
          />

          <BrokersTable
            brokers={brokers}
            isLoadingBrokers={isLoadingBrokers}
            error={error}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            handleMenuAction={handleMenuAction}
            anchorEl={anchorEl}
          />
        </Grid>
      </Grid>
      </Box>
  );
}
