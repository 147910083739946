import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; // or your custom TablePaginationActions component

const LandlordTenantPaymentsTable = ({ paymentData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {paymentData.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: "linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)",
                    "& th": {
                      color: "#333333",
                      fontWeight: "bold",
                      fontSize: "16px",
                      backgroundColor: "#FEF0DE",
                    },
                  }}
                >
                  <TableCell sx={{ padding: "16px" }}>Landlord Name</TableCell>
                  <TableCell sx={{ padding: "16px" }}>Business Name</TableCell>
                  <TableCell sx={{ padding: "16px" }}>Rental Name</TableCell>
                  <TableCell sx={{ padding: "16px" }}>Related Month</TableCell>
                  <TableCell sx={{ padding: "16px" }}>Date Paid</TableCell>
                  <TableCell align="right" sx={{ padding: "16px" }}>
                    Amount
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "16px" }}>
                    Paid By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? paymentData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : paymentData
                ).map((payment) => (
                  <TableRow
                    key={payment.id}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.landlordName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.businessName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.rentalName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.relatedMonth}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.datePaid}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.amount}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {payment.paidBy}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={paymentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments available</Typography>
      )}
    </>
  );
};

export default LandlordTenantPaymentsTable;
