import React, { useRef } from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Sidebar from 'scenes/global/Sidebar';
import Navbar from 'scenes/navbar';

const TenantReceipt = () => {
  const receiptRef = useRef();

  // Static receipt details
  const tenantDetails = {
    name: 'John Doe',
    amount: 'UGX 1,000,000',
    date: 'September 8, 2024',
    paymentMethod: 'Mobile Money',
    balance: 'UGX 500,000',
    propertyLocation: 'Plot 12, Port Bell Road, Kampala',
    telephoneNumber: '+256 701234567',
    landlord: 'Jane Smith',
  };

  // Dummy data for receipt items
  const receiptItems = [
    { description: 'Rent for September 2024', rentPaid: 'UGX 1,000,000', balance: 'UGX 500,000', total: 'UGX 1,000,000' },
    { description: 'Previous Balance', rentPaid: 'UGX 0', balance: 'UGX 500,000', total: 'UGX 500,000' }
  ];

  // Function to print the receipt
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  // Function to download the receipt as a PDF
  const handleDownload = async () => {
    const input = receiptRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 0, 0);
    pdf.save("tenant-receipt.pdf");
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Navbar />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, padding: '0 20px 20px 80px', backgroundColor: '#f9f9f9', overflowY: 'auto' }}>
          {/* Receipt Container */}
          <Box ref={receiptRef} sx={{ 
            paddingBottom: 8, 
            backgroundColor: '#f9f9f9', 
            minHeight: 'calc(100vh - 60px - 80px - 80px)', // Adjust based on Navbar and button height
            overflowY: 'auto',
            marginBottom: 8
          }}>
            {/* Header */}
            <Box sx={{ backgroundColor: 'black', padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <img src="/assets/nav-image.png" alt="RentBeta Logo" style={{ height: 100 }} />
              <Box sx={{ textAlign: 'right', color: 'gold' }}>
                <Typography variant="body1" sx={{ color: 'gold', fontWeight: 'bold' }}>
                  ICT Innovation Hub Nakawa, inside UICT
                </Typography>
                <Typography variant="body1" sx={{ color: 'gold', fontWeight: 'bold' }}>
                  Plot 19-12 Port Bell Road, Kampala, Uganda
                </Typography>
                <Typography variant="body1" sx={{ color: 'gold', fontWeight: 'bold' }}>
                  Tel: +256 703330943 / +256 774453453
                </Typography>
                <Typography variant="body1" sx={{ color: 'gold', fontWeight: 'bold' }}>
                  Website: <a href="https://www.rentbeta.africa" style={{ color: 'gold', textDecoration: 'none' }}>www.rentbeta.africa</a>
                </Typography>
                <Typography variant="body1" sx={{ color: 'gold', fontWeight: 'bold' }}>
                  Email: admin@rentbeta.africa
                </Typography>
              </Box>
            </Box>

            {/* Heading and Bar Above Receipt Items */}
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                Rent Receipt
              </Typography>
              <Box sx={{ backgroundColor: 'black', color: 'white', padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                <Typography variant="body1" sx={{ flex: 1, fontWeight: 'bold' }}>
                  Description
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
                  Rent Paid
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
                  Balance
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
                  Total
                </Typography>
              </Box>
            </Box>

            {/* Receipt Items */}
            <Box sx={{ backgroundColor: 'white', marginTop: 2, padding: 2, borderRadius: 2, boxShadow: 1 }}>
              {receiptItems.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', paddingY: 1 }}>
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {item.description}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>
                    {item.rentPaid}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>
                    {item.balance}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>
                    {item.total}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Receipt Details */}
            <Box sx={{ backgroundColor: 'white', marginTop: 2, padding: 2, borderRadius: 2, boxShadow: 1 }}>
              <Typography variant="h5" sx={{ color: 'black', marginBottom: 2, fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
                Receipt Details
              </Typography>
              <Divider sx={{ backgroundColor: 'black' }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Tenant Name:</strong> {tenantDetails.name}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Rent Amount:</strong> {tenantDetails.amount}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Date:</strong> {tenantDetails.date}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Payment Method:</strong> {tenantDetails.paymentMethod}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, padding: 1 }}>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Balance:</strong> {tenantDetails.balance}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Property Location:</strong> {tenantDetails.propertyLocation}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Telephone Number:</strong> {tenantDetails.telephoneNumber}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Landlord:</strong> {tenantDetails.landlord}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Footer */}
            <Box sx={{ backgroundColor: 'gold', padding: 1, marginTop: 0 }} />
            <Box sx={{ backgroundColor: 'black', padding: 2, marginTop: 0 }}>
              <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
                @RentBeta | /RentBeta | @RentBeta.africa | /rentbeta | <a href="https://www.linkedin.com/company/rentbeta" style={{ color: 'white', textDecoration: 'none' }}>LinkedIn</a>
              </Typography>
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 0, position: 'fixed', bottom: 20, width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'black', color: 'gold', '&:hover': { backgroundColor: 'darkgrey' }, marginRight: 2 }}
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'black', color: 'gold', '&:hover': { backgroundColor: 'darkgrey' } }}
              onClick={handleDownload}
            >
              Download PDF
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TenantReceipt;
