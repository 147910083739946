import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Dummy data
const dummyTenants = [
  { id: 1, tenant_number: "T001", first_name: "John", last_name: "Doe", username: "john.doe@example.com", contact: "1234567890" },
  { id: 2, tenant_number: "T002", first_name: "Jane", last_name: "Doe", username: "jane.doe@example.com", contact: "0987654321" },
  // Add more dummy data as needed
];

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${TableCell.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const BookingTable = ({ tenants, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenantId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    navigate(`/booking-details`); // Navigate to booking details page
    handleClose();
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ fontSize: "18px" }}>Client Contact</StyledTableCell>
            <StyledTableCell style={{ fontSize: "18px" }}>First Name</StyledTableCell>
            <StyledTableCell style={{ fontSize: "18px" }}>Last Name</StyledTableCell>
            <StyledTableCell style={{ fontSize: "18px" }}>Email</StyledTableCell>
            <StyledTableCell style={{ fontSize: "18px" }}>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tenants
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((tenant) => (
              <StyledTableRow key={tenant.id} style={{ fontSize: "18px" }}>
                <StyledTableCell style={{ fontSize: "16px" }}>{tenant.contact}</StyledTableCell>
                <StyledTableCell style={{ fontSize: "16px" }}>{tenant.first_name}</StyledTableCell>
                <StyledTableCell style={{ fontSize: "16px" }}>{tenant.last_name}</StyledTableCell>
                <StyledTableCell style={{ fontSize: "16px" }}>{tenant.username}</StyledTableCell>
                <StyledTableCell style={{ fontSize: "16px" }}>
                  <IconButton
                    onClick={(event) => handleClick(event, tenant.id)}
                    color="primary"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleViewDetails}>View</MenuItem>
                    {/* Add other actions if needed */}
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tenants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};

export default BookingTable;
