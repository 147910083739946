import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate } from 'react-router-dom'; // for navigation

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: '#FEF0DE',
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: 'bold',
  },
  [`&.${TableCell.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const TenantTable = ({ tenants, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
  const navigate = useNavigate(); // use for navigation
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const handleMenuClick = (event, tenant) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenant(tenant);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTenant(null);
  };

  const handleMenuAction = (action) => {
    if (selectedTenant) {
      switch (action) {
        case 'View':
          navigate(`/tenant/${selectedTenant.id}`);
          break;
        case 'Edit':
          navigate(`/tenant/${selectedTenant.id}/edit`);
          break;
        case 'Payments':
          navigate(`/tenant-payments`);
          break;
        case 'Delete':
          // Implement delete logic
          console.log("Deleting tenant with ID:", selectedTenant.id);
          break;
        default:
          break;
      }
    }
    handleMenuClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: '18px' }}>Tenant Number</StyledTableCell>
              <StyledTableCell style={{ fontSize: '18px' }}>First Name</StyledTableCell>
              <StyledTableCell style={{ fontSize: '18px' }}>Last Name</StyledTableCell>
              <StyledTableCell style={{ fontSize: '18px' }}>Username</StyledTableCell>
              <StyledTableCell style={{ fontSize: '18px' }}>Contact</StyledTableCell>
              <StyledTableCell style={{ fontSize: '18px' }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tenant) => (
                <StyledTableRow key={tenant.id} style={{ fontSize: '18px' }}>
                  <StyledTableCell style={{ fontSize: '16px' }}>{tenant.tenant_number}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '16px' }}>{tenant.first_name}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '16px' }}>{tenant.last_name}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '16px' }}>{tenant.username}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '16px' }}>{tenant.contact}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '16px' }}>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, tenant)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleMenuAction('View')}>
                        <VisibilityIcon color="action" sx={{ mr: 1 }} />
                        View
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Edit')}>
                        <EditIcon color="action" sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Payments')}>
                        <PaymentIcon color="action" sx={{ mr: 1 }} />
                        Payments
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Delete')} sx={{ color: 'red' }}>
                        <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tenants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </>
  );
};

export default TenantTable;
