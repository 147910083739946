import * as React from "react";
import axios from "axios";
import {Box, Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import LandlordsTable from "components/Tables/LandlordsTable";
import TopSnack from "components/TopSnack";
import { useLandlords } from "services/hooks/rentals";
import { baseURL } from "services/API";
import FormDialog from "components/modal"; 
import AddLandlordForm from "components/Forms/LandlordForm"; 

const Landlords = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentLandlord, setCurrentLandlord] = React.useState(null);
  const [filterType, setFilterType] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [newLandlord, setNewLandlord] = React.useState({
    phone_number: "",
    first_name: "",
    username: "",
    last_name: "",
    password: "",
    email: "",
    business_name: "",
  });
  const [validationErrors, setValidationErrors] = React.useState({});
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);

  const { landlords, isLoadingLandlords, error } = useLandlords();

  const handleActionClick = (action, landlord) => {
    setCurrentLandlord(landlord);
    setOpenDialog(true);
  };

  const handleDialogClose = () => setOpenDialog(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    Object.keys(newLandlord).forEach((key) => {
      if (!newLandlord[key]) {
        errors[key] = "This field is required";
        isValid = false;
      }
    });
    setValidationErrors(errors);
    return isValid;
  };

  const handleAddLandlordSubmit = async () => {
    if (!validateForm()) {
      setOpenSnack({
        open: true,
        message: "Please fill all fields",
        severity: "error",
      });
      return;
    }

    setIsSubmittingData(true);
    try {
      await axios.post(`${baseURL}/accounts/users/landlords`, newLandlord);
      setOpenSnack({
        open: true,
        message: "Landlord added successfully",
        severity: "success",
      });
      setNewLandlord({
        phone_number: "",
        first_name: "",
        username: "",
        last_name: "",
        password: "",
        email: "",
        business_name: "",
      });
      setValidationErrors({});
      handleDialogClose();
    } catch (error) {
      setOpenSnack({
        open: true,
        message: error.response?.data?.error || "An error occurred",
        severity: "error",
      });
    } finally {
      setIsSubmittingData(false);
    }
  };

  const handleFilterTypeChange = (event) => setFilterType(event.target.value);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewLandlord((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
          >
            Landlords
          </Typography>
          <Box
            sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ flexGrow: 1 }}
            >
              <InputLabel>Filter By</InputLabel>
              <Select
                value={filterType}
                onChange={handleFilterTypeChange}
                label="Filter By"
                sx={{ width: 300, fontSize: 14 }}
              >
                <MenuItem value="landlordNumber">Landlord Number</MenuItem>
                <MenuItem value="landlordName">Landlord Name</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Add Landlord
            </Button>
          </Box>
          <LandlordsTable
            data={landlords}
            onActionClick={handleActionClick}
            loading={isLoadingLandlords}
            error={error}
          />
        </Grid>
      </Grid>

      <TopSnack openSnack={openSnack} setOpenSnack={setOpenSnack} />

      {/* Form Dialog */}
      <FormDialog
        open={openDialog}
        title="Add Landlord"
        text="Please fill in the details of the new landlord."
        content={
          <AddLandlordForm
            newLandlord={newLandlord}
            handleChange={handleChange}
            validationErrors={validationErrors}
          />
        }
        handleClose={handleDialogClose}
        handleSubmit={handleAddLandlordSubmit}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default Landlords;
