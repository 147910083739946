import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, CardMedia, Divider, Box } from '@mui/material';

const BookingDetails = () => {
  // State to store fetched data
  const [property, setProperty] = useState({});
  const [client, setClient] = useState({});
  const [booking, setBooking] = useState({});

  useEffect(() => {
    // Mock function to fetch data
    const fetchData = async () => {
      // Replace with actual API calls
      const propertyData = {
        address: '123 Main St, City, Country',
        type: 'Apartment',
        description: 'A spacious and modern apartment located in the heart of the city.',
        name: 'Median Price Appartment',
        price: '10000/month',
      };
      const clientData = {
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '+123456789',
      };
      const bookingData = {
        bookingDate: 'September 15, 2024',
        checkInDate: 'October 1, 2024',
        checkOutDate: 'October 7, 2024',
        status: 'Confirmed',
      };

      // Set state with fetched data
      setProperty(propertyData);
      setClient(clientData);
      setBooking(bookingData);
    };

    fetchData();
  }, []);

  return (
    <Box m="20px">
          <Typography variant="h5" sx={{ marginLeft: 10, fontSize: '2rem', fontWeight: 'bold' }} component="div">
            Booking Details
          </Typography>
          <Box padding={3}>
            <Grid container spacing={3}>
              {/* Big Card with House Image and Property Details */}
              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    height: 'auto',
                    minHeight: '350px', // Ensure the height is consistent
                    borderRadius: 2,
                    boxShadow: 3
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 300, margin: 2, borderRadius: 2, objectFit: 'cover' }}
                    image="/assets/property.jpg"
                    alt="House Image"
                  />
                  <CardContent sx={{ flexGrow: 1, padding: 3 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: '1.75rem' }} component="div">
                      {property.name}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2, fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      {property.description}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                          <strong>Address:</strong> {property.address}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                          <strong>Type:</strong> {property.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                          <strong>Price:</strong> {property.price}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary">
                          <strong>Description:</strong> {property.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* Client Details Card */}
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 3, height: '100%' }}>
                  <CardContent sx={{ padding: 3, height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: 2 }}>
                      Client Details
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      <strong>Name:</strong> {client.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      <strong>Email:</strong> {client.email}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary">
                      <strong>Phone:</strong> {client.phone}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Booking Details Card */}
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 3, height: '100%' }}>
                  <CardContent sx={{ padding: 3, height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: 2 }}>
                      Booking Details
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      <strong>Booking Date:</strong> {booking.bookingDate}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      <strong>Check-in Date:</strong> {booking.checkInDate}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary" paragraph>
                      <strong>Check-out Date:</strong> {booking.checkOutDate}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.25rem' }} color="text.secondary">
                      <strong>Status:</strong> {booking.status}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
    </Box>
  );
};

export default BookingDetails;
