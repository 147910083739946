import React, { useState } from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import AddTenantForm from 'components/Forms/TenantsAddForm';
import TenantTable from 'components/Tables/LandlorTenantsTable';
import { styled } from '@mui/material/styles';

const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LandlordTenants = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [tenant, setTenant] = useState({
    name: '',
    contact: '',
    amountSaved: '',
    date: '',
    landlordName: '',
    landlordContact: '',
    email: '',
    address: '',
  });
  // Initialize with some dummy data
  const [tenants, setTenants] = useState([
    { id: 1, tenant_number: 'T001', first_name: 'John', last_name: 'Doe', username: 'johndoe', contact: '123-456-7890' },
    { id: 2, tenant_number: 'T002', first_name: 'Jane', last_name: 'Smith', username: 'janesmith', contact: '987-654-3210' },
    { id: 3, tenant_number: 'T003', first_name: 'Alice', last_name: 'Johnson', username: 'alicejohnson', contact: '555-555-5555' },
    { id: 4, tenant_number: 'T004', first_name: 'Bob', last_name: 'Williams', username: 'bobwilliams', contact: '666-666-6666' },
  ]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddTenant = () => {
    setTenants([...tenants, { ...tenant, id: tenants.length + 1 }]);
    setTenant({
      name: '',
      contact: '',
      amountSaved: '',
      date: '',
      landlordName: '',
      landlordContact: '',
      email: '',
      address: '',
    });
    handleCloseDialog();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="20px">
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <TitleContainer>
                <Typography variant="h5" style={{marginBottom: 0, fontWeight: 'bold', fontSize: '25px'}}>
                  Landlord Tenants
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                >
                  Add new Landlord Tenant
                </Button>
              </TitleContainer>
            </Grid>
          </Grid>
          <TenantTable
            tenants={tenants}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <AddTenantForm
            open={openDialog}
            onClose={handleCloseDialog}
            onSubmit={handleAddTenant}
            tenant={tenant}
            setTenant={setTenant}
          />
    </Box>
  );
};

export default LandlordTenants;
