import React, { useState, useEffect } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import { Box, Button } from "@mui/material";
import LandlordTenantPaymentsTable from "components/Tables/LandlordTenantPaymentsTable";
import AddTenantPaymentForm from "components/Forms/LandlordTenantPaymentsForm";

const LandlordDetails = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_landlord_rental: "",
    amount: "",
    description: "",
    date_paid: "",
    related_month: "",
    paid_by: "",
  });
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    const selectedMonthId = monthsAndYears.find(
      (month) => `${month.month} ${month.year}` === selectedMonth
    )?.id || "";
    setNewPayment((prev) => ({
      ...prev,
      related_month: selectedMonthId,
    }));
  };

  const handleAddPayment = () => {
    console.log(newPayment);
    setIsSubmittingData(true);

    axios.post(`${baseURL}/admin/landlord_payments`, newPayment)
      .then(response => {
        console.log("Payment added successfully", response.data);
        // Optionally refresh the payment data here
        handleCloseDialog();
      })
      .catch(error => {
        console.error("There was an error adding the payment!", error);
      })
      .finally(() => {
        setIsSubmittingData(false);
      });
  };

  const paymentData = [
    { id: 1, landlordName: "John Doe", businessName: "Doe Enterprises", rentalName: "Main Street Rental", relatedMonth: "January 2024", datePaid: "2024-01-15", amount: 1200 },
    { id: 2, landlordName: "Jane Smith", businessName: "Smith LLC", rentalName: "Elm Street Rental", relatedMonth: "February 2024", datePaid: "2024-02-15", amount: 1300 },
    { id: 3, landlordName: "Michael Johnson", businessName: "Johnson Properties", rentalName: "Oak Street Rental", relatedMonth: "March 2024", datePaid: "2024-03-15", amount: 1400 },
  ];

  return (
    <Box m="20px">
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ height: 56, width: 200 }}
          onClick={handleOpenDialog}
        >
          Add Payment
        </Button>
      </Box>

      <Box>
        <LandlordTenantPaymentsTable
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paymentData={paymentData}
        />
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <AddTenantPaymentForm
            newPayment={newPayment}
            handleInputChange={handleInputChange}
            handleMonthChange={handleMonthChange}
            setMonthsAndYears={setMonthsAndYears} // Pass setter to load monthsAndYears
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleAddPayment}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default LandlordDetails;
