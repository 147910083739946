import React, { useState } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import { Box, Card, CardContent, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Button, Snackbar, Alert} from "@mui/material";
import LandlordDetailsTable from "components/Tables/LandlordDetailsTable";
import AddPaymentForm from "components/Forms/LandlordPaymentForm";

const LandlordDetails = () => {
  const [page, setPage] = useState(0);
  const [filterMonth, setFilterMonth] = useState("All Months");
  const [openDialog, setOpenDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_landlord_rental: "",
    amount: "",
    description: "",
    date_paid: "",
    related_month: "",
    paid_by: "",
  });
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar

  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    const selectedMonthId = monthsAndYears.find(month => `${month.month} ${month.year}` === selectedMonth)?.id || "";
    setNewPayment((prev) => ({
      ...prev,
      related_month: selectedMonthId,
    }));
  };

  const handleAddPayment = () => {
    setIsSubmittingData(true);
    axios.post(`${baseURL}/admin/landlord_payments`, newPayment)
      .then(response => {
        console.log("Payment added successfully", response.data);
        setOpenSnackbar(true);  // Show Snackbar when payment is successful
        handleCloseDialog();
      })
      .catch(error => {
        console.error("There was an error adding the payment!", error);
      })
      .finally(() => {
        setIsSubmittingData(false);
      });
  };

  const paymentData = [
    { id: 1, date: "2024-01-15", amount: 1200, method: "Credit Card" },
    { id: 2, date: "2024-02-15", amount: 1300, method: "Bank Transfer" },
    { id: 3, date: "2024-03-15", amount: 1400, method: "Credit Card" },
  ];

  const filteredPaymentData =
    filterMonth === "All Months"
      ? paymentData
      : paymentData.filter((payment) =>
          payment.date.startsWith(
            new Date(`${filterMonth} 1, 2024`).toISOString().slice(0, 7)
          )
        );

  const totalAmountReceived = filteredPaymentData.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );

  return (
        <Box m="20px">
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    width: "60%",
                    backgroundColor: "#d1e7fd",
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6">Total Amount Received</Typography>
                    <Typography variant="h4">${totalAmountReceived}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* <Card
                  sx={{
                    width: "60%",
                    backgroundColor: "#fce5cd",
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6">Credit Score</Typography>
                    <Typography variant="h4">{creditScore}</Typography>
                  </CardContent>
                </Card> */}
              </Grid>
            </Grid>

            <Box sx={{ mt: 5, display: "flex", alignItems: "center" }}>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ flexGrow: 1, height: 56, marginRight: 2 }}
              >
                <InputLabel id="filter-by-label">Filter By Month</InputLabel>
                <Select
                  labelId="filter-by-label"
                  value={filterMonth}
                  onChange={handleFilterMonthChange}
                  label="Filter By Month"
                  sx={{ width: 300, fontSize: 14, height: "100%" }}
                >
                  <MenuItem value="All Months">All Months</MenuItem>
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                sx={{ height: 56, width: 200 }}
                onClick={handleOpenDialog}
              >
                Add Payment
              </Button>
            </Box>

            <Box sx={{ mt: 5 }}>
              <LandlordDetailsTable
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                paymentData={filteredPaymentData}
                // landlordId={1}
              />
            </Box>
          </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <AddPaymentForm
            newPayment={newPayment}
            handleInputChange={handleInputChange}
            handleMonthChange={handleMonthChange}
            setMonthsAndYears={setMonthsAndYears} 
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleAddPayment}
        isSubmittingData={isSubmittingData}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Payment added successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LandlordDetails;
