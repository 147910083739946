import React from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from "@mui/material";

const AddTenantPaymentForm = ({
  newPayment,
  handleInputChange,
  handleMonthChange,
  rentals = [], // Default to empty array
  isLoadingRentals,
  errorRentals,
  monthsAndYears = [], // Default to empty array
  isLoadingMonthsAndYears,
  errorMonthsAndYears,
  managers = [], // Default to empty array
  isLoadingManagers,
  errorManagers,
}) => {
  const handleMonthChangeInternal = (event) => {
    const selectedMonth = event.target.value;
    handleMonthChange({
      target: {
        name: "related_month",
        value: selectedMonth, // Use the selected month directly
      },
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Landlord Rental ID</InputLabel>
        <Select
          name="related_landlord_rental"
          value={newPayment.related_landlord_rental || ""}
          onChange={handleInputChange}
          label="Landlord Rental ID"
        >
          {isLoadingRentals ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorRentals ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load rentals</Typography>
            </MenuItem>
          ) : (
            rentals.map((rental) => (
              <MenuItem key={rental.id} value={rental.id}>
                {rental.rental_name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <TextField
        label="Amount"
        type="number"
        name="amount"
        value={newPayment.amount || ""}
        onChange={handleInputChange}
        variant="outlined"
      />
      <TextField
        label="Description"
        name="description"
        value={newPayment.description || ""}
        onChange={handleInputChange}
        variant="outlined"
      />
      <TextField
        label="Date Paid"
        type="date"
        name="date_paid"
        value={newPayment.date_paid || ""}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
      />

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Paid By (Month and Year)</InputLabel>
        <Select
          name="related_month"
          value={monthsAndYears.find(
            (month) => `${month.month} ${month.year}` === newPayment.related_month
          )?.id || ""}
          onChange={handleMonthChangeInternal}
          label="Paid By (Month and Year)"
        >
          {isLoadingMonthsAndYears ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorMonthsAndYears ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load months and years</Typography>
            </MenuItem>
          ) : (
            monthsAndYears.map((item) => (
              <MenuItem key={item.id} value={`${item.month} ${item.year}`}>
                {`${item.month} ${item.year}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Paid By (Manager)</InputLabel>
        <Select
          name="paid_by"
          value={newPayment.paid_by || ""}
          onChange={handleInputChange}
          label="Paid By (Manager)"
        >
          {isLoadingManagers ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorManagers ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load managers</Typography>
            </MenuItem>
          ) : (
            managers.map((manager) => (
              <MenuItem key={manager.id} value={manager.related_user.id}>
                {`${manager.related_user.first_name} ${manager.related_user.last_name}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AddTenantPaymentForm;
